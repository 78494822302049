<!--
 * @FileDescription:网盟-余额页签
 * @Author: 蔡林泽
 * @Date: 2022/3/15
 * @LastModifiedTime: 2022/3/15
 -->
<template>
  <div class="main">
    <h1>Balance</h1>
    <div class="data f">
      <div>
        <p>Available Balance</p>
        <span>US$ {{ Liststats.balance }}</span>
      </div>
      <div>
        <p>Debt</p>
        <span>US$ {{ Liststats.debt }}</span>
      </div>
      <div class="right">
        <el-button :disabled="UserInvite.Count<UserInvite.Total" type="primary" round @click="Withdraw">Withdraw</el-button>
        <p>The number of users who have completed the order : {{ UserInvite.Total }}/{{ UserInvite.Count }}</p>
      </div>
    </div>
    <el-tabs v-model="activeName" style="background: #fff;">
      <el-tab-pane label="Withdrawal" name="1">
        <withdrawal ref="withdrawal" />
      </el-tab-pane>
      <el-tab-pane label="Transaction Record" name="2">
        <transaction ref="transaction" />
      </el-tab-pane>
    </el-tabs>

    <!-- 提现弹窗 -->
    <el-dialog title="Withdraw" :visible.sync="dialogVisible" top="30vh" width="550px" @close="formData = $options.data().formData">
      <div class="Withdraw">
        <h1>Please enter the amount</h1>
        <div class="f">
          <el-input v-model="formData.amount" type="number" style="width:300px" /><span>USD</span>
        </div>
        <div class="f-b" style="margin-top:13px">
          <span style="color:#000000">Receiving Method</span>
          <span class="pointer" style="color:#99C41C" @click="transactionVisible=true">Add New Mothod</span>
        </div>
        <div v-for="item in tradingMethod" :key="item.transaction_method" class="list">
          <el-radio v-model="formData.transaction_method_id" :label="item.transaction_method">{{ item.transaction_method===1?'Paypal':item.transaction_method===2?'Alipay':item.transaction_method===3?'Bank card':item.transaction_method===4?'Payoneer':'' }}</el-radio>
          <span>{{ item.account }}</span>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="Confirm">Confirm</el-button>
        </span>
      </div>
    </el-dialog>

    <!-- 添加交易方式弹窗 -->
    <el-dialog title="Add New Mothod" :visible.sync="transactionVisible" top="30vh" width="550px" @close="methodData = $options.data().methodData">
      <div class="transaction">
        <h1 class="pointer" @click="transactionVisible=false"><i class="iconfont el-icon-arrow-left" />Back</h1>
        <div class="fd-c f">
          <span>Receiving Method</span>
          <el-select slot="prepend" v-model="methodData.transaction_method" placeholder="Transaction Method" style="width:300px;border-radius: 30px;">
            <el-option label="Bank Card(Only for Chinese bank account)" value="3" />
            <el-option label="Alipay" value="2" />
            <el-option label="Payoneer" value="4" />
            <el-option label="PayPal" value="1" />
          </el-select>
          <div v-if="['2','3'].includes(methodData.transaction_method)" class="fd-c f">
            <span>Full Name</span>
            <el-input v-model="methodData.full_name" style="width:300px;" />
          </div>
          <div v-if="['1','4','2','3'].includes(methodData.transaction_method)" class="fd-c f">
            <span>Receiving Account</span>
            <el-input v-model="methodData.account" style="width:300px;" />
          </div>
          <div v-if="['3'].includes(methodData.transaction_method)" class="fd-c f">
            <span>Receiving Bank</span>
            <el-input v-model="methodData.bank" style="width:300px;" />
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="ConfirmMethod">Confirm</el-button>
        </span>
      </div>
    </el-dialog>

    <!-- 发送邮箱弹窗 -->
    <el-dialog title="Send Email" :visible.sync="emailVisible" top="30vh" width="550px" @close="formData.code=undefined">
      <div class="email">
        <h1>The verification code has been sent to your email.</h1>
        <p style="line-height: 25px;">{{ emailRender||'' }}</p>
        <el-input v-model="formData.code" style="width:300px;" />
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="ConfirmEmail">Confirm</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import API from '@/api/index'
import list from '@/mixin/list'
import Transaction from '@/views/balance/components/Transactions.vue'
import Withdrawal from '@/views/balance/components/Withdrawals.vue'

export default {
  components: { Transaction, Withdrawal },
  mixins: [list],
  data() {
    return {
      Liststats: '',
      activeName: '1',
      UserInvite: '',
      dialogVisible: false,
      formData: {},
      methodData: {
        transaction_method: '1',
        code: ''
      },
      tradingMethod: [],
      transactionVisible: false,
      emailVisible: false,
      emailRender: ''
    }
  },
  created() {
    this.getUserfunds()
    this.getUserInvite_stats()
  },
  methods: {
    getUserInvite_stats() {
      API.getUserInvite_stats().then(res => {
        this.UserInvite = res.data
      })
    },
    getUserfunds() {
      API.getUserfunds().then(res => {
        this.Liststats = res.data
      })
    },
    Withdraw() {
      this.dialogVisible = true
      API.gettransactionMethod().then(res => {
        this.tradingMethod = res.data.list
      })
    },
    Confirm() {
      if (!this.formData.transaction_method_id || !this.formData.amount) return this.$message.warning('Please complete the data')
      if (+this.formData.amount > +this.Liststats.balance) return this.$message.warning('Withdrawal amounts are not Greater than account balance')
      API.sendCode().then(res => {
        API.getUserInfo().then(res => {
          this.emailRender = res.data.email
        })
        this.$message.success('Sent successfully')
        this.emailVisible = true
      })
    },
    ConfirmMethod() {
      if (!this.methodData.account) return this.$message.warning('Please complete the data')
      if (['2'].includes(this.methodData.transaction_method)) {
        if (!this.methodData.full_name) return this.$message.warning('Please complete the data')
      }
      if (['3'].includes(this.methodData.transaction_method)) {
        if (!this.methodData.full_name) return this.$message.warning('Please complete the data')
        if (!this.methodData.bank) return this.$message.warning('Please complete the data')
      }
      let params = {
        transaction_method: this.methodData.transaction_method,
        full_name: ['2', '3'].includes(this.methodData.transaction_method) ? this.methodData.full_name : undefined,
        account: this.methodData.account,
        bank: ['3'].includes(this.methodData.transaction_method) ? this.methodData.bank : undefined
      }
      let formData = new FormData()
      if (params.transaction_method)formData.append('transaction_method', params.transaction_method)
      if (params.full_name)formData.append('full_name', params.full_name)
      if (params.account)formData.append('account', params.account)
      if (params.bank)formData.append('bank', params.bank)
      API.addtransactionMethod(formData).then(res => {
        this.$message.success('success')
        this.transactionVisible = false
        this.Withdraw()
      })
    },
    ConfirmEmail() {
      if (!this.formData.code) return this.$message.warning('Please complete the data')
      let formData = new FormData()
      formData.append('amount', this.formData.amount)
      formData.append('transaction_method_id', this.tradingMethod.filter(item => item.transaction_method === this.formData.transaction_method_id)[0].id)
      formData.append('code', this.formData.code)
      API.withdrawal(formData).then(res => {
        this.emailVisible = false
        this.dialogVisible = false
        this.$message.success('Success')
        this.activeName === '1' ? this.$refs.withdrawal.getList() : this.$refs.transaction.getList()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.main {
  .data {
    width: 100%;
    height: 120px;
    margin: 10px 0;
    background: #ffffff;
    position: relative;
    div {
      // margin: 40px;
      padding-top: 20px;
      margin: 0 110px;
      p {
        line-height: 43px;
        font-weight: 500;
        font-size: 16px;
        color: #747a87;
      }
      span {
        font-weight: 500;
        font-size: 26px;
        color: #000000;
      }
    }
    .right {
      // float: r;
      button {
        position: absolute;
        right: 34px;
        top: 17px;
      }
      p {
        position: absolute;
        top: 68px;
        right: 34px;
      }
    }
  }
}
.tableStyle {
  /deep/.el-table__body-wrapper {
    overflow-x: hidden;
  }
}
/deep/ .el-tabs__nav {
  margin-left: 20px;
}
.Withdraw {
  > h1 {
    font-size: 14px;
    color: #747a87;
    margin-bottom: 10px;
  }
  .f {
    /deep/ .el-input__inner {
      border-radius: 30px;
    }
    span {
      font-size: 14px;
      line-height: 32px;
      color: #000000;
      margin-left: 10px;
    }
  }
  .list {
    margin-top: 13px;
    span {
      display: block;
      width: 210px;
      height: 33px;
      background: #f2f2f2;
      border-radius: 6px;
      line-height: 33px;
      text-indent: 1em;
      margin-left: 22px;
      margin-top: 5px;
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.transaction {
  h1 {
    font-size: 18px;
    color: #747a87;
    font-weight: 100;
  }
  .fd-c {
    margin-top: 15px;
    span {
      margin-bottom: 6px;
    }
  }

  /deep/ .el-input__inner {
    border-radius: 30px;
  }
}
.email {
  h1 {
    font-size: 18px;
  }
  /deep/ .el-input__inner {
    border-radius: 30px;
    margin-top: 10px;
  }
  /deep/ .el-input__inner {
    border-radius: 30px;
  }
}
</style>
