<!--
 * @FileDescription: 网盟-交易记录
 * @Author: 蔡林泽
 * @Date: 2022/3/15
 * @LastModifiedTime: 2022/3/15
 -->
<template>
  <div>
    <div class="list-filter bg-white f">
      <!-- 搜索 -->
      <el-input v-model="input" clearable placeholder="please Choose" class="input-with-select" style="width:400px;">
        <el-select slot="prepend" v-model="select" placeholder="please Choose" style="width:150px;">
          <el-option label="Transaction ID" value="withdraw_no" />
          <el-option label="Transaction Account" value="transaction_account" />
        </el-select>
        <el-button slot="append" icon="el-icon-search" @click="resetPage" />
      </el-input>

      <el-select slot="prepend" v-model="listForm.transaction_method" clearable placeholder="Transaction Method" style="width:170px;" @change="resetPage">
        <el-option v-for="item in transactionMethod" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>

      <el-select slot="prepend" v-model="listForm.status" clearable placeholder="Transaction Status" style="width:150px;" @change="resetPage">
        <el-option label="Pending" value="1" />
        <el-option label="Completed" value="2" />
        <el-option label="Canceled" value="3" />
      </el-select>

      <el-select v-model="timeSelect" placeholder="please Choose" style="width:150px;margin-right:0px;" @change="pickerChange">
        <el-option label="Created Date" value="1" />
        <el-option label="Updated Date" value="2" />
      </el-select>
      <el-date-picker v-model="time" type="daterange" value-format="yyyy-MM-dd" range-separator="-" start-placeholder="Start date" end-placeholder="End date" @change="pickerChange" />
    </div>
    <div class="buttomStyle ">
      <i class="iconfont icon-a-download-2-line3" />
      <span class="pointer" @click="exportVisible=true">Export</span>
    </div>
    <TableContainer :current="listForm.page" :total="total" :height="($store.state.common.clientHeight-415)+'px'" @change="pageChange">
      <el-table ref="table" v-loading="loading" :data="list" :height="($store.state.common.clientHeight-470)+'px'" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" />
        <el-table-column prop="withdraw_no" label="Transaction ID" min-width="200" />
        <el-table-column label="Transaction Method" min-width="200">
          <template slot-scope="scope">
            <p>{{ transactionMethod.filter(item=>item.value===+scope.row.transaction_method)[0].label }}</p>
          </template>
        </el-table-column>
        <el-table-column label="Transaction Account" min-width="200">
          <template slot-scope="scope">
            <p>{{ scope.row.UserTransactionMethod.account }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="Amount(USD)" min-width="200" />
        <el-table-column label="Status" min-width="200">
          <template slot-scope="scope">
            <p>{{ +scope.row.status===1?'Pending':+scope.row.status===2?'Completed':'Canceled' }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="Created Date" min-width="200" />
        <el-table-column prop="audit_at" label="Updated Date" min-width="200" />
        <el-table-column label="Action" width="200" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" @click.passive="handleEdit(scope.row)">Details</el-button>
            <el-button v-show="scope.row.status===1" size="mini" @click.passive="handleCancel(scope.row)">Cancel</el-button>
          </template>
        </el-table-column>
      </el-table>
    </TableContainer>
    <!-- 详情弹窗 -->
    <el-dialog title="Details" :visible.sync="changeVisible" width="600px" top="30vh" :close-on-click-modal="false" :close-on-press-escape="false">
      <el-descriptions :column="1" size="default">
        <el-descriptions-item label="Transaction ID">{{ dataList.withdraw_no||'-' }}</el-descriptions-item>
        <el-descriptions-item label="Amount">{{ dataList.amount }}</el-descriptions-item>
        <el-descriptions-item label="Status">{{ +dataList.status===1?'Pending':+dataList.status===2?'Completed':+dataList.status===3?'Canceled':'-' }}</el-descriptions-item>
        <el-descriptions-item label="Created Date">{{ dataList.created_at||'-' }}</el-descriptions-item>
        <el-descriptions-item label="Updated updated_at">{{ dataList.updated_at||'-' }}</el-descriptions-item>
        <el-descriptions-item label="Transaction Method">{{ dataList.transaction_method ===1?'Paypal':dataList.transaction_method ===2?'Alipay':dataList.transaction_method ===3?'Bank card':dataList.transaction_method ===4?'Payoneer':'-' }}</el-descriptions-item>
        <el-descriptions-item label="Transaction Account">{{ dataList.payment_account||'-' }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
    <!-- 导出弹窗 -->
    <el-dialog title="Export" :visible.sync="exportVisible" width="350px" top="30vh" :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="f-c">
        <el-button type="primary" @click="exportDetails('Results')">Export By Search Results</el-button>
        <el-button :disabled="multipleSelection.length<1" @click="exportDetails('Filter')">Export By Filter</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import API from '@/api/index'
import list from '@/mixin/list'
import { downloadBlobFile } from '@/util/common.js'

const transactionMethod = [
  { label: 'Paypal', value: 1 },
  { label: 'Alipay', value: 2 },
  { label: 'Bank card', value: 3 },
  { label: 'Payoneer', value: 4 }
]
export default {
  mixins: [list],
  data() {
    return {
      transactionMethod,
      loading: false,
      changeVisible: false,
      dataList: '',
      select: 'withdraw_no',
      input: '',
      time: '',
      timeSelect: '1',
      exportVisible: false,
      multipleSelection: []
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      let params = Object.assign({}, this.listForm, { [this.select]: this.input || undefined })
      this.loading = true
      API.getWithdrawsList(params).then(res => {
        this.list = res.data.list || []
        this.total = res.data.pages.total
      }).finally(() => {
        this.loading = false
      })
    },
    handleEdit(data) {
      API.getWithdrawDetail(data.id).then(res => {
        this.changeVisible = true
        this.dataList = res.data
      })
    },
    handleCancel(data) {
      API.cancelWithdrawal(data.id).then(res => {
        this.$message.success('Cancel Successful')
        this.getList()
      })
    },
    pickerChange() {
      if (!this.time) {
        this.listForm.updated_at_start = undefined
        this.listForm.updated_at_end = undefined
        this.listForm.created_at_start = undefined
        this.listForm.created_at_end = undefined
      } else if (this.timeSelect === '1' && this.time) {
        this.listForm.created_at_start = this.time[0]
        this.listForm.created_at_end = this.time[1]
        this.listForm.updated_at_start = undefined
        this.listForm.updated_at_end = undefined
      } else if (this.timeSelect === '2' && this.time) {
        this.listForm.updated_at_start = this.time[0]
        this.listForm.updated_at_end = this.time[1]
        this.listForm.created_at_start = undefined
        this.listForm.created_at_end = undefined
      }
      this.getList()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    exportDetails(type) {
      let params = ''
      if (type === 'Results') {
        params = Object.assign({}, this.listForm, { [this.select]: this.input || undefined })
        delete params.page
        delete params.per_page
      } else if (type === 'Filter') {
        params = {
          ids: this.multipleSelection.map(item => item.id)
        }
      }
      API.exportWithdraw(params).then(res => {
        this.$message.success('success')
        downloadBlobFile(res, `export.xlsx`)
        this.exportVisible = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.buttomStyle{
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  i {
      font-size: 20px;
      margin-right: 5px;
    }
    span{
      line-height: 21px;
    }
}
</style>
